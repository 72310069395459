






































import { gsap } from 'gsap'

import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { FlexibleKeyList } from '@/inc/types'

export default defineComponent({
  name: 'FlexibleIconList',
  props: {
    content: {
      type: Object as () => FlexibleKeyList,
      required: true,
    },
  },

  setup() {
    const item = ref<HTMLElement | null>(null)
    const title = ref()
    const tl = ref()

    tl.value = gsap.timeline()

    onMounted(() => {
      tl.value
        .set(item.value, {
          opacity: 0,
          y: 20,
        })
        .set(title.value!.$el, {
          opacity: 0,
          y: 20,
        })
    })

    const onAppear = isInViewport => {
      if (isInViewport) {
        tl.value
          .to(
            title.value!.$el,
            {
              duration: 0.5,
              opacity: 1,
              y: 0,
              ease: 'power4.out',
            },
            '0'
          )
          .to(
            item.value,
            {
              duration: 0.5,
              opacity: 1,
              stagger: 0.05,
              y: 0,
              ease: 'power4.out',
            },
            '<0.5'
          )
      }
    }

    return {
      title,
      onAppear,
      item,
    }
  },
})
